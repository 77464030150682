import '@/lenis'
import 'liquid-ajax-cart'

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'
import AlpineMorph from '@alpinejs/morph'
import AlpineParent from '@ryangjchandler/alpine-parent'
// import { Core } from '@unseenco/taxi'

// import FadeTransition from '@/transitions/fade'
// import { BaseRenderer } from '@/renderers/default'
import AlpineGlobals from '@/alpine/index'
import { globalFormManager } from '@/utils/form'

class App {
	taxi!: Core
	modules: any

	constructor() {
		setTimeout(this.init, 0)
	}

	init = () => {
		// Register and initialize AlpineJS
		window.Alpine = Alpine

		Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineMorph, AlpineParent])
		AlpineGlobals.register(Alpine)
		Alpine.start()

		globalFormManager.init()

		// this.taxi = new Core({
		// 	links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.jdgm-paginate__page)',
		// 	removeOldContent: false,
		// 	renderers: {
		// 		default: BaseRenderer
		// 	},
		// 	transitions: {
		// 		default: FadeTransition
		// 	}
		// })

		// window.taxi = this.taxi
	}
}

window._app = new App()
