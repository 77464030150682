export default {
	name: 'pdpColorSelect',
	component(config) {
		const variants = JSON.parse(this.$el.dataset.variants)

		return {
			selectedColor: config?.defaultColor || '',
			variants,
			submitButton: {} as HTMLButtonElement,
			isColorPanelOpen: false,

			getVariant(color) {
				const selectedVariant = this.variants.find(variant => {
					return variant.options.some(option => {
						return option === color
					})
				})

				this.submitButton.disabled = !selectedVariant?.available
				// this.idInput.value = selectedVariant.id
				this.$el.setAttribute('data-variant-id', selectedVariant.id)
			},

			init() {
				this.$nextTick(() => {
					this.submitButton = document.getElementsByClassName('add-to-cart-button')[0] as HTMLButtonElement

					this.$watch('selectedColor', newValue => {
						this.getVariant(newValue)
					})

					this.$watch('isColorPanelOpen', isOpen => {
						if (!isOpen) {
							setTimeout(() => {
								window._getLenis()
								window._lenis?.scrollTo(0)
							}, 50)
						}
					})
				})
			}
		}
	}
}
