const KLAVIYO_COMPANY_ID = 'VVwdpp'
const KLAVIYO_LIST_FR_ID = window.newsletterIDs.fr
const KLAVIYO_LIST_EN_ID = window.newsletterIDs.en

interface StepCardClickEventType {
	step: string
	id: number | string
	title: string
	label?: string
	price: number
	image: string
	url?: string
}

interface StepStateType {
	id?: number | string
	title?: string
	label?: string
	price: number
	image?: string
	isSubmitted: boolean
	isVisited: boolean
	url?: string
}

interface SwitchOptionChangeEventType {
	optionKey: string
	title: string
	isChecked: boolean
	labelOn: string
	labelOff: string
	imageOn: string
	imageOff: string
}

interface OptionCardClickEventType {
	optionKey: string
	title: string
	image: string
	label: string
	price: number
}

interface OptionStateType {
	isClicked: boolean
	isChecked: boolean
	title?: string
	image?: string
	price: number
	label?: string
}

type CurrentSidebarType = 'steps' | 'summary'

interface CouchConfiguratorDataType {
	config: any
	productTitle: string
	price: string
	stepsOrder: string[]
	currentSidebar: CurrentSidebarType
	previousStep?: string
	currentStep: string
	steps: Record<string, StepStateType>
	options: Record<string, OptionStateType>
	estimatePopin: {
		isVisible: boolean
		email?: string
		isLoading: boolean
		isRgpdChecked: boolean
		isNewsletterChecked: boolean
		isSuccess: boolean
		hasError: boolean
	}
	navItemsEl?: HTMLElement[]
	init: () => void
	handleStepCardClick: (event: StepCardClickEventType) => void
	handleStepSubmit: ({ step }: { step: string }) => void
	handleStepNavClick: ({ step }: { step: string }) => void
	handleSwitchOptionChange: (event: SwitchOptionChangeEventType) => void
	handleOptionCardClick: (event: OptionCardClickEventType) => void
	handleSummaryEditClick: ({ stepKey }: { stepKey: string }) => void
	handleEstimateFormSubmit: () => void
	isSwitchOptionImageVisible: ({ optionKey, value }: { optionKey: string; value: string }) => boolean
	scrollNavIntoView: ({ currentStepKey }: { currentStepKey: string }) => void
	getFabricPrice: ({ unitPrice }: { unitPrice: number }) => number
	getOptionLabel: ({ optionKey }: { optionKey: string }) => string
	getOptionPrice: ({ optionKey }: { optionKey: string }) => number
	getSlideshowImageZindex: ({ imageStep }: { imageStep: string }) => number
	resetStep: ({ key }: { key: string }) => void
	resetOptions: () => void
	resetEstimatePopin: () => void
	computePrice: () => void
	formatPrice: (price: number) => string
}

const DEFAULT_STEP_STATE: StepStateType = {
	id: undefined,
	title: undefined,
	price: 0,
	image: undefined,
	isSubmitted: false,
	isVisited: false
}

const DEFAULT_OPTION_STATE: OptionStateType = {
	isChecked: false,
	isClicked: false,
	price: 0
}

const formatPrice = (price: number) => {
	// Current UK store is using euros too, might switch currency when they switch price ?
	return (price / 100).toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
}

const capitalize = (string?: string) => {
	if (!string) return ''

	const [firstLetter, ...restOfWord] = string

	return firstLetter.toUpperCase() + restOfWord.join('')
}

const decodeHTMLEntities = string => {
	const textArea = document.createElement('textarea')
	textArea.innerHTML = string
	return textArea.value
}

export default {
	name: 'couchConfigurator',
	component(props): CouchConfiguratorDataType {
		const initialSteps = props.stepsOrder.reduce((acc, key) => {
			return {
				...acc,
				[key]: { ...DEFAULT_STEP_STATE }
			}
		}, {})

		initialSteps[props.stepsOrder[0]].isVisited = true

		// populate options with default state
		const initialOptions = props.config.options?.reduce((acc, key) => {
			return {
				...acc,
				[key]: { ...DEFAULT_OPTION_STATE }
			}
		}, {})

		return {
			productTitle: decodeHTMLEntities(props.productTitle),
			config: props.config,
			stepsOrder: props.stepsOrder,
			price: formatPrice(props.basePrice),
			previousStep: undefined,
			currentStep: props.stepsOrder[0],
			// currentStep: props.stepsOrder[4],
			currentSidebar: 'steps',
			steps: initialSteps,
			options: initialOptions,
			estimatePopin: {
				isVisible: false,
				email: undefined,
				isRgpdChecked: false,
				isNewsletterChecked: false,
				isLoading: false,
				isSuccess: false,
				hasError: false
			},
			navItemsEl: undefined,
			init() {
				// DEBUG
				// this.steps = {
				// 	dimensions: {
				// 		id: 0,
				// 		title: 'Dimensions',
				// 		price: 360000,
				// 		image: 'https://cdn.shopify.com/s/files/1/0046/8087/6150/files/cc-victor-canape-droit-2-coussins.jpg?v=1724238909',
				// 		isSubmitted: true,
				// 		isVisited: true,
				// 		label: 'L.190 x l.103 x H.80'
				// 	},
				// 	materials: {
				// 		id: 'lin',
				// 		title: 'Matieres',
				// 		price: 0,
				// 		image: 'https://cdn.shopify.com/s/files/1/0046/8087/6150/files/cc-material-lin.jpg?v=1724412719',
				// 		isSubmitted: true,
				// 		isVisited: true,
				// 		label: 'lin'
				// 	},
				// 	fabrics: {
				// 		id: 'vellino',
				// 		title: 'Tissus',
				// 		price: 203000,
				// 		image: 'https://cdn.shopify.com/s/files/1/0046/8087/6150/files/cc-fabric-vellino.jpg?v=1724420398',
				// 		isSubmitted: true,
				// 		isVisited: true,
				// 		label: 'vellino',
				// 		url: '/products/echantillon-tissu-vellino'
				// 	},
				// 	colors: {
				// 		id: 'celadon',
				// 		title: 'Couleurs',
				// 		price: 0,
				// 		image: 'https://cdn.shopify.com/s/files/1/0046/8087/6150/files/cc-color-artaban-acier.jpg?v=1724679014',
				// 		isSubmitted: true,
				// 		isVisited: true,
				// 		label: 'celadon'
				// 	},
				// 	options: {
				// 		price: 0,
				// 		isSubmitted: false,
				// 		isVisited: true
				// 	}
				// }

				this.$nextTick(() => {
					this.navItemsEl = this.$refs.root.querySelectorAll('.js-nav-item')
				})

				if (window.location.hash === '#configurator') {
					this.$store.global.isCouchConfiguratorVisible = true
				}

				this.$watch('$store.global.isCouchConfiguratorVisible', value => {
					this.$store.global.bodyScrollLock(value)
				})

				this.$watch('currentStep', value => {
					this.$refs.sidebar.scrollTo({
						top: 0
					})

					this.scrollNavIntoView({ currentStepKey: value })
				})

				this.$watch('currentSidebar', (value: CurrentSidebarType) => {
					this.$refs.sidebar.scrollTo({
						top: 0
					})

					if (value === 'steps') {
						setTimeout(() => {
							this.scrollNavIntoView({ currentStepKey: this.currentStep })
						}, 0)
					}
				})

				this.$watch('steps', () => {
					this.computePrice()
				})

				this.$watch('options', () => {
					this.computePrice()
				})
			},
			handleStepCardClick({ step, ...data }) {
				this.steps[step] = {
					...this.steps[step],
					...data
				}
			},
			handleStepSubmit({ step }) {
				this.steps[step].isSubmitted = true
				const currentStepIndex = this.stepsOrder.findIndex(value => value === this.currentStep)
				const nextStep = this.stepsOrder[currentStepIndex + 1]
				const lastStep = this.stepsOrder[this.stepsOrder.length - 1]

				if (step === lastStep) {
					this.currentSidebar = 'summary'
				} else {
					this.steps[nextStep].isVisited = true
					this.currentStep = nextStep
				}

				// Debug
				// console.log('handleStepSubmit', { steps: this.steps })

				this.computePrice()
			},
			handleStepNavClick({ step }) {
				if (this.steps[step].isVisited) {
					this.previousStep = this.currentStep
					this.currentStep = step
				}
			},
			handleSwitchOptionChange({ optionKey, title, labelOn, labelOff, imageOn, imageOff }) {
				const { isChecked } = this.options[optionKey]

				const image = isChecked ? imageOn : imageOff
				const label = isChecked ? labelOn : labelOff

				this.options[optionKey] = {
					isClicked: true,
					isChecked,
					title,
					image,
					label,
					price: 0
				}
			},
			handleOptionCardClick({ optionKey, ...data }) {
				this.options[optionKey] = {
					...this.options[optionKey],
					...data,
					isClicked: true
				}

				this.options[optionKey].isChecked = !this.options[optionKey].isChecked
			},
			handleSummaryEditClick({ stepKey }) {
				const stepEditIndex = this.stepsOrder.findIndex(key => key === stepKey)
				const stepKeyToReset = this.stepsOrder.slice(stepEditIndex + 1, this.stepsOrder.length)

				// erase every steps after the one we're going to edit, since every step choice / price depends on the previous one
				stepKeyToReset.forEach(key => {
					this.resetStep({ key })
				})

				// we only need to reset options if we want to edit dimensions
				// since options choice / price depends on dimension choice
				if (stepKey === 'dimensions') {
					this.resetOptions()
				}

				this.currentStep = stepKey
				this.currentSidebar = 'steps'
			},
			async handleEstimateFormSubmit() {
				if (!this.estimatePopin.email || !this.estimatePopin.isRgpdChecked) {
					this.estimatePopin.hasError = true
					this.estimatePopin.isLoading = false
					return
				}

				this.computePrice()

				const options = Object.keys(this.options)
					.filter(key => this.options[key].isChecked)
					.map(key => {
						const option = this.options[key]

						return option.label ? `${option.title} (${option.label})` : option.title
					}, '')
					.join(', ')

				let response

				this.estimatePopin.isLoading = true
				/* eslint-disable @typescript-eslint/naming-convention */
				try {
					const headers = new Headers()
					headers.append('Content-Type', 'application/json')
					headers.append('Accept', 'application/json')
					headers.append('Revision', '2024-07-15')

					const { locale } = window.Shopify
					const newsletterId = locale === 'fr' ? KLAVIYO_LIST_FR_ID : KLAVIYO_LIST_EN_ID

					response = await fetch(`https://a.klaviyo.com/client/events/?company_id=${KLAVIYO_COMPANY_ID}`, {
						method: 'POST',
						headers,
						body: JSON.stringify({
							data: {
								type: 'event',
								attributes: {
									properties: {
										'Language devis': 'fr',
										'Titre devis': this.productTitle,
										'Image devis': this.steps.dimensions.image,
										'Dimensions devis': this.steps.dimensions.label,
										'Matière devis': capitalize(this.steps.materials.label),
										'Tissu devis': capitalize(this.steps.fabrics.label),
										'Couleur devis': capitalize(this.steps.colors.label),
										'Options devis': options,
										'Total devis': this.price
									},
									metric: {
										data: {
											type: 'metric',
											attributes: {
												name: 'Demande de devis'
											}
										}
									},
									profile: {
										data: {
											type: 'profile',
											attributes: {
												email: this.estimatePopin.email
											}
										}
									}
								}
							}
						})
					})

					if (this.estimatePopin.isNewsletterChecked) {
						await fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${KLAVIYO_COMPANY_ID}`, {
							method: 'POST',
							headers,
							body: JSON.stringify({
								data: {
									type: 'subscription',
									attributes: {
										profile: {
											data: {
												type: 'profile',
												attributes: {
													email: this.estimatePopin.email
												}
											}
										}
									},
									relationships: {
										list: {
											data: {
												type: 'list',
												id: newsletterId
											}
										}
									}
								}
							})
						})
					}
				} catch (error) {
					console.log('Error', error)
					this.estimatePopin.hasError = true
					this.estimatePopin.isLoading = false
				}
				/* eslint-enable @typescript-eslint/naming-convention */

				if (response?.ok) {
					this.estimatePopin.hasError = false
					this.estimatePopin.isLoading = false
					this.estimatePopin.isSuccess = true
				} else {
					this.estimatePopin.hasError = true
					this.estimatePopin.isLoading = false
				}
			},
			scrollNavIntoView({ currentStepKey }) {
				if (this.$store.global.device === 'mobile') {
					if (this.navItemsEl === undefined) return

					const target = Array.from(this.navItemsEl).find(el => el.dataset.stepKey === currentStepKey)

					// console.log(target, this.navItemsEl, currentStepKey)

					target?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
				}
			},
			isSwitchOptionImageVisible({ optionKey, value }) {
				if (!this.options[optionKey]) {
					return value === 'left'
				}

				return value === 'right' ? this.options[optionKey].isChecked : !this.options[optionKey].isChecked
			},
			getFabricPrice({ unitPrice }) {
				if (this.steps.dimensions.id === undefined) return 0

				const footage = this.config.variants[this.steps.dimensions.id].footage
				return unitPrice * footage
			},
			getOptionPrice({ optionKey }) {
				const dimensionId = this.steps.dimensions.id
				if (dimensionId === undefined) return 0

				const variantOption = this.config.variants?.[dimensionId].options?.[optionKey]

				// Price are sometime stored within the variant option itself
				return variantOption?.price * 100 || parseInt(variantOption, 10) * 100 || 0
			},
			getOptionLabel({ optionKey }) {
				const dimensionId = this.steps.dimensions.id
				if (dimensionId === undefined) return ''

				const variantOption = this.config.variants?.[dimensionId].options?.[optionKey]

				return variantOption?.label || ''
			},
			getSlideshowImageZindex({ imageStep }) {
				const imageStepIndex = this.stepsOrder.findIndex(value => value === imageStep)
				const currentStepIndex = this.stepsOrder.findIndex(value => value === this.currentStep)
				const previousStepIndex = this.stepsOrder.findIndex(value => value === this.previousStep)

				if (imageStep === 'summary') {
					return this.currentSidebar === 'summary' ? 2 : 0
				} else if (imageStepIndex === currentStepIndex) {
					return 2
				} else if (imageStepIndex === previousStepIndex) {
					return 1
				} else {
					return 0
				}
			},
			resetStep({ key }) {
				this.steps[key] = { ...DEFAULT_STEP_STATE }
			},
			resetOptions() {
				Object.keys(this.options).forEach(key => {
					this.options[key] = {
						...DEFAULT_OPTION_STATE
					}
				})
			},
			resetEstimatePopin() {
				this.estimatePopin = {
					isVisible: false,
					email: undefined,
					isRgpdChecked: false,
					isNewsletterChecked: false,
					isSuccess: false,
					hasError: false,
					isLoading: false
				}
			},
			computePrice() {
				const stepsPrice = Object.keys(this.steps).reduce((acc, key) => {
					const price = this.steps[key].price || 0
					return acc + price
				}, 0)

				const optionsPrice = Object.keys(this.options).reduce((acc, key) => {
					const price = this.options[key].isChecked ? this.options[key].price : 0
					return acc + price
				}, 0)

				this.price = formatPrice(stepsPrice + optionsPrice)
			},
			formatPrice(price) {
				return formatPrice(price)
			}
		}
	}
}
