import { supportsPassiveEvents } from 'detect-it'

import { clamp } from '@/utils/maths'
import { offsetLeft } from '@/utils/offset'

interface ConfigProps {
	loop?: boolean
	edgeContrains?: boolean
	centerElement?: boolean
	preventDragDesktop?: boolean
	onRest?: () => void
	onDrag?: (dragging: boolean, direction: number) => void
	onChange?: (index: number, dragging: boolean) => void
}

const defaultConfig = {
	loop: false,
	edgeContrains: true,
	centerElement: false,
	preventDragDesktop: false,
	onRest: null,
	onDrag: null,
	onChange: null
}

const dragFrictions = 0.52
const frictions = 0.9
const borderFrictions = 0.55

export default {
	name: 'slideshowJs',
	component(config: ConfigProps) {
		const _config = { ...defaultConfig, ...config }
		const { loop, edgeContrains, centerElement, preventDragDesktop, onRest, onDrag, onChange } = _config

		const children = this.$refs.wrapper?.children
		const snapping = true
		const computePositionIndexTo = -1

		const activeClassName = 'slide-active'
		const snapClassName = ''

		return {
			enabled: false,
			index: 0,
			buttonState: -1,

			children,
			centerIndex: 0,
			loop,
			centerElement,
			centerDestX: 0,

			// Refs (will be set in init())
			containerRef: null as HTMLElement | null,
			wrapperRef: null as HTMLElement | null,
			elementsRef: [] as HTMLElement[],

			// Other variables (similar to useRef in React)
			dragging: false,
			scrolling: false,
			controlling: false,
			needsUpdate: false,
			indexRef: 0,
			positionIndexRef: 0,
			groupBy: 1,
			groupByRef: 1,
			groupIndexRef: 0,
			totalRef: 0,
			computePositionIndexTo,
			preventDragDesktop,

			containerBoundingClientRect: { width: 0, left: 0 },
			windowSize: { innerWidth: 0, innerHeight: 0 },
			resizeObserver: {} as ResizeObserver,

			width: 0,
			x: 0,
			y: 0,
			vx: 0,
			ox: 0,
			initX: 0,
			initmx: 0,
			omx: 0,
			mx: 0,
			mvx: 0,
			my: 0,
			destX: 0,
			direction: 0,
			constrainsEdges: [] as number[],
			stopped: true,
			dragged: false,
			startDragX: 0,
			startDragY: 0,
			mouseDown: false,

			handleObserver(entries) {
				entries.forEach((entry, i) => {
					const target = entry.target
					if (!target.__scale) target.__scale = 1
					if (!target.__x) target.__x = 0

					// we can't use boundingClientRect if the ref is transformed
					// target.__width = entry.boundingClientRect.width
					target.__width = entry.target.offsetWidth
					target.__xInit = entry.target.offsetLeft

					if (!target.__x) target.__x = 0

					if (this.resizeObserver) {
						this.resizeObserver.unobserve(target)
					}
				})
				this.computeIndex(this.indexRef)
				this.needsUpdate = true
				this.tick(true)
			},

			renderChildren() {
				this.elementsRef = []
				const childrenArr: HTMLElement[] = Array.from(children)
				childrenArr.forEach((element, i) => {
					if (element) {
						this.elementsRef[i] = element
						this.elementsRef[i].classList.add('shrink-0')
					}
				})
				this.elementsRef = this.elementsRef.filter(Boolean)
				this.wrapperRef?.replaceChildren(...this.elementsRef.filter(Boolean))
				this.totalRef = (this.elementsRef.length / this.groupBy + (this.elementsRef.length % this.groupBy === 0 ? 0 : 1)) | 0
			},

			tick(now = false) {
				if (!this.enabled || !this.elementsRef?.length || !this.containerBoundingClientRect.width) {
					return
				}

				if (this.dragging) {
					this.destX = this.initX - this.mx
					this.vx += (this.destX - this.x) * 0.25
					this.vx *= dragFrictions
					this.controlling = false
				} else if (snapping || this.controlling) {
					this.vx += (this.destX - this.x) * (now ? 1 : 0.15)
					this.vx *= dragFrictions
				}

				// constrains (if not looping)
				if (edgeContrains) {
					const contrainSpring = now ? 1 : this.dragging ? 0.8 : 0.1
					const left = this.constrainsEdges[0]
					const right = this.constrainsEdges[1]

					if (!loop && this.x + this.vx < left) {
						this.vx += (left - this.x) * contrainSpring
						this.vx *= borderFrictions
					} else if (!loop && this.x + this.vx > right) {
						this.vx += (right - this.x) * contrainSpring
						this.vx *= borderFrictions
					} else {
						this.vx *= frictions
					}
				}

				if (now) {
					this.x = this.destX
					this.vx = 0
				} else {
					this.x += this.vx
				}

				if (this.dragging) {
					this.vx = this.x - this.ox
				} else if (this.vx > -0.3 && this.vx < 0.3 && !this.stopped) {
					this.stopped = true
					if (onRest) onRest()
				}

				// rounding values
				this.x = ((this.x * 100) | 0) / 100
				let delta = this.x - this.destX
				if (delta < 0) delta *= -1
				if (delta < 0.01) this.x = this.destX
				if (this.ox !== this.x) this.needsUpdate = true

				if (this.needsUpdate) this.updateDom()

				this.ox = this.x
			},

			updateDom() {
				if (!this.elementsRef) return
				for (let i = 0, li = this.elementsRef.length; i < li; i++) {
					const element = this.elementsRef[i]
					if (!element || element.__xInit === undefined) continue

					let elementX = -this.x + element.__xInit

					if (this.centerElement) {
						this.centerDestX = (this.containerBoundingClientRect.width - this.elementsRef[this.centerIndex].__width) / 2
						elementX += this.centerDestX
					}

					if (this.loop) {
						if (elementX + element.__width < -this.containerBoundingClientRect.left) {
							const r = ((Math.abs(elementX + element.__width) / this.width) | 0) + 1
							elementX += this.width * r
						} else if (elementX - this.windowSize.innerWidth > -this.containerBoundingClientRect.left) {
							const r = (((elementX - this.windowSize.innerWidth) / this.width) | 0) + 1
							elementX -= this.width * r
						}
					}

					const inView =
						elementX + element.__width >= -this.containerBoundingClientRect.left &&
						elementX - this.containerBoundingClientRect.left <= this.windowSize.innerWidth

					if (inView) {
						element.__x = elementX - element.__xInit

						element.style.transform = `translate3d(${element.__x}px,${this.y}px,0)`
						if (activeClassName) {
							element.classList.add(activeClassName)
						}

						if (!element.__inView) {
							element.style.visibility = ''
							element.style.willChange = 'transform'
						}
					} else if (element.__inView !== false) {
						element.style.transform = ''
						element.style.visibility = 'hidden'
						element.style.willChange = ''
						if (activeClassName) {
							element.classList.remove(activeClassName)
						}
					}
					if (snapping && i === this.indexRef && snapClassName) {
						element.classList.add(snapClassName)
					} else if (snapClassName) {
						element.classList.remove(snapClassName)
					}

					element.__inView = inView
				}
				this.needsUpdate = false
			},

			resize(withTick = true) {
				if (this.containerRef && this.width && this.containerBoundingClientRect) {
					this.constrainsEdges = [0, this.width - this.containerBoundingClientRect.width]

					this.enabled = this.width > this.containerRef.clientWidth
					if (this.enabled && this.elementsRef) {
						this.elementsRef.forEach(element => {
							if (element && this.resizeObserver) {
								this.resizeObserver.observe(element)
							}
						})

						this.updateButtonState(this.indexRef)

						if (this.elementsRef[this.indexRef]?.__xInit) {
							this.computePositionIndex(this.positionIndexRef)
							if (withTick) this.tick(true)
						}
					}
				}
			},

			startDrag(e) {
				if ((e.button !== undefined && e.button !== 0) || this.preventDragDesktop) {
					return
				}

				const event = (e.touches && e.touches[0]) || e

				this.startDragX = event.pageX
				this.startDragY = event.pageY
				this.dragged = false

				this.stopped = false
				this.mx = event.pageX
				this.omx = this.mx
				this.initmx = this.mx

				this.initX = this.mx + this.x

				this.vx = 0
				this.mvx = 0

				this.my = event.pageY

				this.dragging = true
				this.scrolling = false
			},

			drag(e) {
				if (this.dragging && this.wrapperRef && !this.preventDragDesktop) {
					const event = (e.touches && e.touches[0]) || e
					this.mx = event.pageX
					this.mvx = this.mx - this.omx
					this.my = event.pageY

					if (this.startDragX !== event.pageX) {
						this.dragged = true
					}

					const dx = this.mx - this.initmx
					if (Math.abs(dx) > 30) {
						this.direction = Math.sign(dx)
						this.wrapperRef.style.pointerEvents = 'none'
					} else if (Math.abs(this.mvx) > 1) {
						this.direction = Math.sign(this.mvx)
						this.wrapperRef.style.pointerEvents = 'none'
					} else {
						this.direction = 0
					}

					this.omx = this.mx

					if (onDrag) onDrag(this.dragging, this.direction)
				}
			},

			stopDrag(e) {
				if (this.dragging && this.wrapperRef && !this.preventDragDesktop) {
					this.wrapperRef.style.pointerEvents = ''

					if (snapping) {
						this.computePositionIndex(this.positionIndexRef - this.direction * this.groupByRef)
					}
					this.dragging = false
					this.direction = 0
					this.mouseDown = false
					if (onDrag) onDrag(this.dragging, this.direction)
				}
			},

			computePositionIndex(i) {
				const computedIndex = this.loop
					? i < 0
						? (this.elementsRef.length - (-i % this.elementsRef.length)) % this.elementsRef.length
						: i % this.elementsRef.length
					: clamp(i, 0, this.elementsRef.length - 1 - ((this.elementsRef.length - 1) % this.groupByRef))

				if (!this.elementsRef[computedIndex]) return

				this.updateDestX(computedIndex, i)

				this.index = computedIndex
				this.indexRef = computedIndex
				this.positionIndexRef = this.loop ? i : computedIndex

				this.updateButtonState(computedIndex)

				if (onChange && this.indexRef !== computedIndex) onChange(this.index, this.dragging)
			},

			computeIndex(i) {
				const positionIndex = this.loop ? i + (this.positionIndexRef - this.indexRef) : i
				this.computePositionIndex(positionIndex)
			},

			listenComputePositionIndexTo() {
				this.$watch('computePositionIndexTo', () => {
					if (computePositionIndexTo !== undefined && computePositionIndexTo !== -1) {
						this.computeIndex(computePositionIndexTo)
					}
				})
			},

			updateDestX(computedIndex, index) {
				if (this.loop && this.elementsRef[computedIndex]) {
					this.destX = this.elementsRef[computedIndex].__xInit + ((index ? index - computedIndex : 0) / this.elementsRef.length) * this.width
				} else if (!this.loop) {
					this.destX = clamp(
						this.elementsRef[index] ? this.elementsRef[index].__xInit : this.elementsRef[computedIndex].__xInit,
						this.constrainsEdges[0],
						this.constrainsEdges[1] + (this.centerElement ? 2 * this.centerDestX : 0)
					)
				}
				if (this.centerElement) {
					if (computedIndex !== 0) {
						this.destX += (this.containerBoundingClientRect.width - this.elementsRef[0].__width) / 2
						this.destX -= (this.containerBoundingClientRect.width - this.elementsRef[computedIndex].__width) / 2
					}
				}
			},

			updateButtonState(i) {
				const index = i / this.groupByRef + 1
				this.buttonState = i === 0 ? -1 : index >= this.elementsRef.length / this.groupByRef ? 1 : 0
			},

			handlePrev() {
				this.controlling = true
				this.computePositionIndex(this.positionIndexRef - this.groupBy)
			},

			handleNext() {
				this.controlling = true
				this.computePositionIndex(this.positionIndexRef + this.groupBy)
			},

			setIndex(i: number, now?: boolean) {
				this.computePositionIndex(i)
				if (now) this.tick(true)
			},

			handleDragStart(e) {
				e.preventDefault()
			},

			handleMouseDown(e) {
				this.mouseDown = true
				this.startDrag(e)
			},

			handleTouchStart(e) {
				this.startDrag(e)
			},

			addDragEvents() {
				const passive = supportsPassiveEvents ? { passive: true } : false
				window.addEventListener('mousemove', this.drag.bind(this), passive)
				window.addEventListener('touchmove', this.drag.bind(this), passive)
				window.addEventListener('mouseup', this.stopDrag.bind(this), passive)
				window.addEventListener('touchend', this.stopDrag.bind(this), passive)

				if (this.containerRef) {
					this.containerRef.addEventListener('mousedown', this.handleMouseDown.bind(this), passive)
					this.containerRef.addEventListener('touchstart', this.startDrag.bind(this), passive)
					this.containerRef.addEventListener('dragstart', this.handleDragStart.bind(this))
				}
			},
			removeDragEvents() {
				window.removeEventListener('mousemove', this.drag)
				window.removeEventListener('touchmove', this.drag)
				window.removeEventListener('mouseup', this.stopDrag)
				window.removeEventListener('touchend', this.stopDrag)

				if (this.containerRef) {
					this.containerRef.removeEventListener('mousedown', this.handleMouseDown)
					this.containerRef.removeEventListener('touchstart', this.handleTouchStart)
					this.containerRef.removeEventListener('dragstart', this.handleDragStart)
				}
			},

			init() {
				this.containerRef = this.$refs.container as HTMLElement
				this.wrapperRef = this.$refs.wrapper as HTMLElement

				this.renderChildren()

				this.$useResizeObserver(this.containerRef, e => {
					this.containerBoundingClientRect.width = e.contentRect.width
					this.containerBoundingClientRect.left = e.contentRect.left || offsetLeft(this.containerRef) || 0
					this.windowSize = {
						innerWidth: window.innerWidth,
						innerHeight: window.innerHeight
					}
					this.resize()
				})

				this.$useResizeObserver(this.wrapperRef, e => {
					this.width = e.contentRect.width
					this.resize()
				})

				this.$useRaf(() => {
					this.tick()
				})

				this.updateDom()

				this.addDragEvents()

				this.preventDragDesktop = preventDragDesktop && this.$store.global.device === 'desktop'
				this.$watch('$store.global.device', device => {
					this.preventDragDesktop = preventDragDesktop && device === 'desktop'
				})

				this.resizeObserver = new IntersectionObserver(this.handleObserver.bind(this))

				this.listenComputePositionIndexTo()
			},

			destroy() {
				this.removeDragEvents()

				this.resizeObserver?.disconnect()
			}
		}
	}
}
